import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import FourImage from "../images/404.jpg"

const About = () => (
  <Layout>
    <SEO title="404" />
    <div className="container py-8 w-full flex-row text-center">
      <h1>Something's Missing...</h1>
      <div className="flex w-full justify-center text-center">
        <img src={FourImage} alt="404" />
      </div>
      <p>Sorry — we don't have a page by that name.</p>
      <p>
        <Link to="/">Click here</Link> to go back home. If you followed a link
        on this site that led here, please let us know!
      </p>
    </div>
  </Layout>
)

export default About
